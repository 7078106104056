// @ts-nocheck
export default {
    props: {
        items: {
            type: Array,
            default() {
                return []
            }
        },
        value: {
            type: Object,
            default() {
                return null
            }
        }
    },
    data() {
        return {
            model: null
        }
    },
    watch: {
        model: {
            handler: function(val) {
                this.$emit('input', val)
            },
            deep: true,
            immediate: true
        },
        value: {
            handler: function(val) {
                this.model = val
            },
            deep: true,
            immediate: true
        },
    }
}