<template>
  <div>
    <label class="section__lk-label" for="model"><span>Выберите тему</span></label>
    <v-select :searchable="false" :clearable="false" id="model" :loading="!items.length" :disabled="!items.length" label="seminar_name" name="model" :options="items" v-model="model" />
    <div class="section__lk-under-label" v-if="model">
      <div class="section__lk-under-label-time">
        <img class="icon" src="/pic/profile/clock.svg" alt="icon-clock" />
        {{ model.academic_duration }} {{ model.academic_duration | pluralize('ru', ['час', 'часа', 'часов']) }}
      </div>
      <div class="section__lk-under-label-separator"></div>
      <div class="section__lk-under-label-link">
        <router-link :to="'/education/jiznennyy-cikl-eksportnogo-proekta/' + model.alias">Подробнее о семинаре</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import selectFilterMixin from "@/mixins/selectFilterMixin";

export default {
  name: "SeminarThemeFilter",
  mixins: [selectFilterMixin]
}
</script>

<style scoped>

</style>